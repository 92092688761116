import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import SelectSeat from "../../components/SelectSeat";
import SeatDiscountSelect from "../../components/SeatDiscountSelect";
import Indicators from "../../components/Indicators";

export class SeatSelect extends Component {
  state = {
    discounts: [],
    schema: [],
    blocked: [],
  };

  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  componentDidMount() {
    fetch("https://backoffice.autobusyvkj.cz/api/webapp/discounts")
      .then((response) => {
        return response.json();
      })
      .then((discounts) => {
        this.setState({
          discounts: discounts,
          // selectedDiscount: discounts.filter(
          //   (t) => t.id === this.props.values.tariff.id
          // )[0],
        });
      })
      .catch((error) => console.error(error));

    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "lineStopFrom=" +
        this.props.values.selectedConnection.stop_from_id +
        "&lineStopTo=" +
        this.props.values.selectedConnection.stop_to_id,
    };

    fetch("https://backoffice.autobusyvkj.cz/api/webapp/price", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        this.props.handleChange("basePrice")({
          target: {
            value: parseInt(myJson),
          },
        });
      })
      .catch((error) => console.error(error));

    requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "date=" +
        this.props.values.departure +
        "&connectionId=" +
        this.props.values.selectedConnection.connection_id,
    };

    fetch("https://backoffice.autobusyvkj.cz/api/webapp/seats", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        console.log(JSON.stringify(myJson));
        let schema = [];
        let lines = myJson.schema.data.split("\n");
        lines.forEach((line) => {
          let cells = line.split(",");
          let seats = [];
          cells.forEach((seat) => {
            if (seat.trim() == "") {
              seats.push(null);
            } else {
              seats.push(parseInt(seat));
            }
          });
          schema.push({
            seats: seats,
          });
        });

        let blocked = [];
        for (let i = 0; i < myJson.reservations.length; i++) {
          blocked.push(parseInt(myJson.reservations[i]));
        }

        this.setState({
          schema: schema,
          blocked: blocked,
        });
      })
      .catch((error) => console.error(error));
  }

  onDiscountSelected(seatInfo) {
    let seats = this.props.values.seatsWithTariffs.filter(
      (info) => info.seat !== seatInfo.seat
    );
    seats = seats.concat(seatInfo);
    seats.sort((a, b) => {
      return a.seat - b.seat;
    });
    console.log("selected discounts " + JSON.stringify(seats));
    this.props.handleChange("seatsWithTariffs")({ target: { value: seats } });
  }

  onSeatsSelected(e) {
    this.props.handleChange("seatsWithTariffs")(e);
  }

  render() {
    return (
      <div>
        <div className="firstRow">
          <h1 className="titleReservation">Zvolte tarif a sedadlo</h1>
        </div>

        <div className="secondRow">
          <SelectSeat
            schema={this.state.schema}
            blocked={this.state.blocked}
            defaultSeats={this.props.values.seatsWithTariffs}
            onChange={this.onSeatsSelected.bind(this)}
          />
          <SeatDiscountSelect
            basePrice={this.props.values.basePrice}
            seats={this.props.values.seatsWithTariffs}
            discounts={this.state.discounts}
            onDiscountSelected={this.onDiscountSelected.bind(this)}
          />
          <div className="priceCont">
            <h4>
              Celková cena:{" "}
              {this.props.values.seatsWithTariffs.reduce(
                (total, info) =>
                  total +
                  Math.round(
                    (info.tariff?.rate ?? 1) * this.props.values.basePrice
                  ),
                0
              )}
              Kč
            </h4>
          </div>
        </div>

        <div className="thirdRow">
          <Button color="secondary" variant="contained" onClick={this.back}>
            Zpět
          </Button>

          <Button
            disabled={this.props.values.seatsWithTariffs.length === 0}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Pokračovat
          </Button>

          <Indicators />
        </div>
      </div>
    );
  }
}

export default SeatSelect;
