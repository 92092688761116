import React from "react";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import { Helmet } from "react-helmet";
import MenuHam from "../MenuHam/MenuHam";

function Calculation() {
  return (
    <div>
      <Helmet>
        <title>Kalkulace</title>
      </Helmet>
      <MenuHam />
      <Menu />
      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <h1>Kalkulace</h1>
        </div>
      </div>

      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default Calculation;
