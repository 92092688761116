import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet/es/Helmet";
import Menu from "./Menu";
import { LinearProgress, TextField } from "@material-ui/core";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { Capacitor } from "@capacitor/core";

export class StornoForm extends Component {
  state = {
    code: "",
    price: "",
    isFinished: false,
    error: null,
  };

  confirm = (e) => {
    e.preventDefault();

    const url = "https://backoffice.autobusyvkj.cz/api/webapp/refund/confirm";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "token=" + this.props.token,
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        this.setState({ isFinished: true });
      })
      .catch((error) => console.error(error));
  };

  async componentDidMount() {
    console.log("refund token " + this.props.token);
    const url = "https://backoffice.autobusyvkj.cz/api/webapp/refund/info";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "token=" + this.props.token,
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        console.log(JSON.stringify(myJson));
        if (myJson.error) {
          this.setState({ error: myJson.error });
        } else {
          this.setState({ code: myJson.code, price: myJson.finalPrice });
        }
      })
      .catch((error) => {
        this.setState({ error: "Nepodařilo se vrátit jízdenku." });
        console.error(error);
      });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Storno jízdenky</title>
        </Helmet>
        <Menu />

        <div className="bodyContHP">
          <div className="bodyInnerHP">
            <div className="firstRow">
              <h1 className="titleReservation">Storno jízdenky</h1>
            </div>

            <div className="secondRow summaryTable">
              {this.state.isFinished ? (
                <div>
                  <p>
                    Jízdenka {this.state.code} stornována, o refundaci budete
                    informováni emailem.
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    Jízdenka: <b>{this.state.code}</b>
                  </p>
                  <p>
                    Částka k refundaci: <b>{this.state.price},- Kč</b>
                  </p>
                  <p>{this.state.error}</p>
                </div>
              )}
            </div>

            <div className="thirdRow">
              {!this.state.isFinished && !this.state.error ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.confirm}
                >
                  Potvrdit
                </Button>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>

        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  }
}

export default StornoForm;
