import React from 'react';
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import {Helmet} from "react-helmet";

function References() {
    return (
        <div>
            <Helmet>
                <title>Reference</title>
            </Helmet>
            <Menu />
            <div className="bodyContHP">
                <div className="bodyInnerHP">
                    <h1>Reference</h1>

                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default References;
