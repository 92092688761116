import styled from "styled-components";

export const StyledBackButton = styled.button`
  position: absolute;
  top: 1.5rem;
  left: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 4rem;
  height: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  span {
    width: 2rem;
    height: 2rem;
    position: relative;
    top: 0;
    left: 1rem;
    transform-origin: 1px;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    line-height: 2rem;
  }
`;
