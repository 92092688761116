import React from 'react';
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import Button from "../Button";
import podpis from '../../assets/images/podpis.png';
import {Helmet} from "react-helmet";
import MenuHam from '../MenuHam/MenuHam';


function Company() {
    return (
        <div>
            <Helmet>
                <title>Společnost</title>
            </Helmet>
            <MenuHam/>
            <Menu />

            <div className="bodyContHP">
                <div className="bodyInnerHP">
                    <div className="leftSide">
                        <h1>Naše společnost</h1>
                        <p>„Naše společnost působí v oboru autobusové dopravy, zprvu v rámci České republiky a později i v oblasti dopravování osob po celé Evropě, od roku 1990. Již od začátku se firma etablovala jako flexibilní a pro klientsky orientovaná.  Základ společnosti tvořila vize poskytnout lidem možnost dostupně cestovat, zpřístupnit jim do té doby nepřístupné. Tato vize se s každoročními několika desítkami tisíc cestujících již dlouhodobě naplňuje, a proto si stanovujeme cíle další, které posouvají naši společnost kupředu oproti konkurenci. V současnosti zajišťujeme zájezdovou dopravu pro cestovní kanceláře, školy a různé zájmové skupiny, dále provozujeme několik vnitrostátních a mezinárodních linek, zabezpečujeme také svozy zaměstnanců do firem a pomáháme při pořádání dopravy na akcích, jako jsou hudební festivaly a kongresy. Díky zkušenostem, kterých jsme nabyli v průběhu let, jsme schopni rychle a adekvátně posloužit našemu zákazníkovi. Zakládáme si na tradici, protože velmi dobře víme, že v tradici se nachází i budoucnost a ta spolu s našimi zásadami tvoří to, čím jsme.“
                        </p>
                        <img className="podpis" src={podpis} alt="Podpis" />
                    </div>
                    <div className="rightSide">
                        {/* <Button name="REFERENCE" link="/references" />
                        <Button name="HISTORIE A SOUČASNOST" link="/company" /> */}
                        <Button name="KARIÉRA" link="/career" />
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default Company;
