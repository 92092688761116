import React, { useState } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import MenuHam from "../MenuHam/MenuHam";
const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  error: {
    marginTop: 5,
    color: "#ff0000",
  },
});

function Register(props) {
  const { classes } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div>
      <Helmet>
        <title>VKJ Autobusy</title>
      </Helmet>
      <MenuHam />
      <Menu />
      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <main className={classes.main}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Registrace
              </Typography>
              <form
                className={classes.form}
                onSubmit={(e) => e.preventDefault() && false}
              >
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="name">Jméno a příjmení</InputLabel>
                  <Input
                    id="name"
                    name="name"
                    autoComplete="off"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">E-mail</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="phone">Telefonní číslo</InputLabel>
                  <Input
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Heslo</InputLabel>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <div className={classes.error}>{errorMessage}</div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onRegister}
                  className={classes.submit}
                >
                  Registrovat
                </Button>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/login"
                  className={classes.submit}
                >
                  Zpět na přihlášení
                </Button>
              </form>
            </Paper>
          </main>
        </div>
      </div>
      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );

  async function onRegister() {
    try {
      const response = await fetch("https://dev2.autobusyvkj.cz/api/register", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          name,
          password,
          email,
          phone,
        }), // body data type must match "Content-Type" header
      });
      //return response.json(); // parses JSON response into native JavaScript objects
      console.log(response.json());
      props.history.replace("/dashboard");
    } catch (error) {
      setErrorMessage(error.message);
    }
  }
}

export default withRouter(withStyles(styles)(Register));
