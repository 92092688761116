import React, { Component } from 'react';
import {Helmet} from "react-helmet/es/Helmet";
import Menu from "./Menu";
import {LinearProgress} from "@material-ui/core";
import Footer from "./Footer";
import { Link } from 'react-router-dom'
import MenuHam from "./MenuHam/MenuHam";

export class Failed extends Component {

    render() {
        return (
            <div>

                <Helmet>
                    <title>Shrnutí objednávky</title>
                </Helmet>
                <MenuHam />
                <Menu />

                <div className="bodyContHP">
                    <div className="bodyInnerHP">
                        <div className="firstRow">
                            <h1 className="titleReservation">Platba neúspěšná!</h1>
                        </div>


                        <div className="secondRow">
                            <h2>Prosím zopakujte objednávku.</h2>
                        </div>

                        <Link className="myButtonFinish" to="/">DOMŮ</Link>

                        <h3 className="progressTitle">Krok 6 z 6</h3>
                        <LinearProgress className="progressBar" variant="determinate" value={100}/>
                    </div>
                </div>



                <Footer/>
            </div>
        );
    }
}

export default Failed;
