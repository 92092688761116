import React, { useEffect, useState } from "react";
import Menu from "../Menu";
import map from "../../assets/images/mapa.png";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import Button from "../Button";
import ButtonTables from "../ButtonTables";
import { Helmet } from "react-helmet";
import "../../assets/styles/style.css";
import MenuHam from "../MenuHam/MenuHam";

function TimeTables() {
  const [lines, setLines] = useState(null);

  useEffect(() => {
    if (lines) {
      return;
    }

    fetch("https://backoffice.autobusyvkj.cz/api/schedule/line", {
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        setLines(data);
      })
      .catch((error) => console.error("Lines loading error " + error));
  }, []);

  return (
    <div>
      <Helmet>
        <title>Jízdní řády</title>
      </Helmet>
      <MenuHam />
      <Menu />
      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <div className="leftSide">
            <h1>Jízdní řády</h1>

            <img className="map" src={map} alt="Mapa" />
          </div>
          <div className="rightSide">
            {lines &&
              lines.map((l) => (
                <a
                  key={l.line_id}
                  className="myButtonTables"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={
                    "https://backoffice.autobusyvkj.cz/uploads/pdf-schedules/" +
                    l.line_number +
                    "-" +
                    l.line_id +
                    ".pdf"
                  }
                >
                  {l.line_name}
                </a>
              ))}
            <a
              className="myButtonTables"
              rel="noopener noreferrer"
              target="_blank"
              href="https://backoffice.autobusyvkj.cz/uploads/pdf-schedules/spp.pdf"
            >
              Tarif a smluvní přepravní podmínky dopravce
            </a>
          </div>
        </div>
      </div>

      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default TimeTables;
