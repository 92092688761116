import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet/es/Helmet";
import Menu from "../../components/Menu";
import { LinearProgress } from "@material-ui/core";
import Footer from "../../components/Footer";
import MenuHam from "../../components/MenuHam/MenuHam";
import { Browser, Capacitor } from "@capacitor/core";
import moment from "moment";

export class Summary extends Component {
  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    //this.props.nextStep();

    const url = "https://backoffice.autobusyvkj.cz/api/webapp/payment/create";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "email=" +
        this.props.values.email +
        "&phone=" +
        this.props.values.phoneNumber +
        "&name=" +
        this.props.values.name +
        "&date=" +
        this.props.values.departure +
        "&connection_id=" +
        this.props.values.selectedConnection.connection_id +
        "&line_stop_from_id=" +
        this.props.values.selectedConnection.stop_from_id +
        "&line_stop_to_id=" +
        this.props.values.selectedConnection.stop_to_id +
        "&seats=" +
        JSON.stringify(this.props.values.seatsWithTariffs),
    };

    console.log(JSON.stringify(requestOptions));

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        console.log(JSON.stringify(myJson));
        window.location.href = myJson.redirect_url;
      })
      .catch((error) => console.error(error));
  };

  back = (e) => {
    e.preventDefault();
    if (this.props.values.loggedIn) {
      this.props.addStep(-2);
    } else {
      this.props.prevStep();
    }
  };

  render() {
    const {
      values: {
        selectedConnection,
        name,
        phoneNumber,
        email,
        departure,
        basePrice,
        isTermsApproved,
        seatsWithTariffs,
      },
    } = this.props;
    console.log("Basket: " + JSON.stringify(seatsWithTariffs));
    return (
      <div>
        <div className="firstRow">
          <h1 className="titleReservation">Shrnutí:</h1>
        </div>

        <div className="secondRow summaryTable">
          <p>
            Datum:{" "}
            <b>
              {moment(
                departure +
                  " " +
                  selectedConnection.departure_time.substr(0, 2) +
                  ":" +
                  selectedConnection.departure_time.substr(2, 2)
              ).format("D. M. YYYY HH:mm")}
            </b>
          </p>
          <p>
            Z: <b>{selectedConnection.stop_from}</b>
          </p>
          <p>
            Do: <b>{selectedConnection.stop_to}</b>
          </p>
          <p>
            Spoj: <b>{selectedConnection.line_number}</b> -{" "}
            <b>{selectedConnection.connection_number}</b>
          </p>
          <p>
            Jízdenky:{" "}
            {seatsWithTariffs.map((info) => (
              <span className="summaryTicket">
                {info.seat} - {info.tariff?.name ?? "Obyčejné jízdné"}{" "}
                {Math.round((info.tariff?.rate ?? 1) * basePrice)} Kč
              </span>
            ))}
            <b>
              <br />
              Celková cena:{" "}
              {seatsWithTariffs.reduce(
                (total, info) =>
                  total + Math.round((info.tariff?.rate ?? 1) * basePrice),
                0
              )}{" "}
              Kč
            </b>
          </p>
          <br />
          <p>
            Jméno a příjmení: <b>{name}</b>
          </p>
          <p>
            E-mail: <b>{email}</b>
          </p>
          <p>
            Telefonní číslo: <b>{phoneNumber}</b>
          </p>
          <p></p>
          <p>
            Tarif: <b></b>
          </p>

          <p>
            <input
              name="isTermsApproved"
              type="checkbox"
              defaultChecked={isTermsApproved}
              onChange={this.props.handleChange("isTermsApproved")}
            />
            Souhlasím s cenou a podmínkami.&nbsp;
            <a href="/data/web/SPP_VKJ.pdf" target="_blank">
              Přečtěte si obchodní podmínky.
            </a>
          </p>
        </div>

        <div className="thirdRow">
          <Button color="secondary" variant="contained" onClick={this.back}>
            Zpět
          </Button>

          <Button
            disabled={!this.props.values.isTermsApproved}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Koupit
          </Button>
        </div>
      </div>
    );
  }
}

export default Summary;
