import React, { Component } from "react";
import { Helmet } from "react-helmet/es/Helmet";
import { LinearProgress, Menu } from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuHam from "../components/MenuHam/MenuHam";
import Footer from "../components/Footer";
import { Capacitor } from "@capacitor/core";

export class Success extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Shrnutí objednávky</title>
        </Helmet>
        <MenuHam />
        <Menu />

        <div className="bodyContHP">
          <div className="bodyInnerHP">
            <div className="firstRow">
              <h1 className="titleReservation">Probíhá zpracování platby!</h1>
            </div>

            <div className="secondRow">
              <h2>
                Potvrzení o objednávce a jízdenka vám budou odeslány na Váš
                email.
              </h2>
            </div>

            <Link className="myButtonFinish" to="/">
              DOMŮ
            </Link>
            <Link className="myButtonFinish" to="/dashboard">
              MŮJ ÚČET
            </Link>

            <h3 className="progressTitle">Krok 6 z 6</h3>
            <LinearProgress
              className="progressBar"
              variant="determinate"
              value={100}
            />
          </div>
        </div>

        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  }
}

export default Success;
