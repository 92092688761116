import React, { useState, useRef } from "react";

import FocusLock from "react-focus-lock";
import Burger from "../Burger";
import MenuMob from "../MenuMob";
import { useOnClickOutside } from "../hooks";
import { Capacitor } from "@capacitor/core";
import BackButton from "../BackButton/BackButton";

function MenuHam() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));

  if (Capacitor.getPlatform() === "web") {
    return (
      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <MenuMob open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    );
  } else {
    return <BackButton />;
  }
}

export default MenuHam;
