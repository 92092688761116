import React, { useState, useRef } from 'react';
import Menu from "../Menu";
import Banner from "../Banner";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import ContactBlock from "../ContactBlock";
import {Helmet} from "react-helmet";
import Burger from '../Burger';
import MenuMob from '../MenuMob';
import { useOnClickOutside } from '../hooks';
import MenuHam from '../MenuHam/MenuHam';

function Contact() {
    
    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    return (
        <div>
            <Helmet>
                <title>Kontakt</title>
            </Helmet>
            <MenuHam />
            <Menu />
            <div className="bodyContContact">
                <div className="bodyInnerContact">
                    <h1>Kontakt</h1>

                    <ContactBlock name="Dispečink zájezdy" number="+420 727 828 530" time="NONSTOP" />
                    <ContactBlock name="Plánování zájezdů" number="+420 606 267 227" time="PO-PÁ, 8-16H" />
                    <ContactBlock name="Linková doprava" number="+420 376 311 022" time="PO-PÁ, 8-16H" />
                    <ContactBlock name="Správa nemovitostí" number="+420 777 495 149" time="NONSTOP" />

                    <Banner/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Contact;
