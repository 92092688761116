import React from "react";
import Menu from "../Menu";
import Button from "../Button";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import { Helmet } from "react-helmet";
import MenuHam from "../MenuHam/MenuHam";

function Tours() {
  return (
    <div>
      <Helmet>
        <title>Zájezdová doprava</title>
      </Helmet>
      <MenuHam/>
      <Menu />

      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <div className="leftSide">
            <h1>Zájezdová doprava</h1>
            <p>
              Zajišťujeme téměř veškerý sortiment nepravidelné dopravy od
              transferů, školních výletů, po dopravu mezinárodní. Máme zkušený
              tým řidičů, kteří obětují své práci maximum. Vyřídíme veškeré
              náležitosti spojené s dopravou, jako jsou vjezdová povolení do
              zahraničí, odvodová daň, ale i komunikace se zahraničními úřady.
              Jsme schopni pružně reagovat na Vaše požadavky i v průběhu cesty,
              tak aby Váš prožitek z ní byl co nejlepší.
              <ul>
                <li>KALKULACE ZDARMA</li>
                <li>NAPLÁNOVÁNÍ TRASY</li> <li>VJEZDOVÁ POVOLENÍ</li>
                <li>ODVODOVÉ DANĚ</li> <li>KOMUNIKACE S ÚŘADY</li>
              </ul>
            </p>
          </div>
          <div className="rightSide">
            {/* <Button name="KALKULACE ZDARMA" link="/calculation" />
            <Button name="NAPLÁNOVÁNÍ TRASY" link="/route" />
            <Button name="VJEZDOVÁ POVOLENÍ" link="/permissions" />
            <Button name="ODVODOVÉ DANĚ" link="/taxes" />
            <Button name="KOMUNIKACE S ÚŘADY" link="/comunication" /> */}
          </div>
        </div>
      </div>

      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default Tours;
