import React from "react";
import "../assets/styles/style.css";
import BlockMenu from "./BlockMenu";
import lupa from "../assets/images/lupa.png";
import chair from "../assets/images/chair.png";
import letter from "../assets/images/letter.png";
import { faTags, faUser } from "@fortawesome/free-solid-svg-icons";

function SecondMenu() {
  return (
    <div id="secondMenuHp">
      <BlockMenu name={"Koupit jízdenky"} link={"/reservation"} icon={lupa} />
      <BlockMenu name={"Moje jízdenky"} link={"/tickets"} icon={chair} />
      <BlockMenu name={"Můj účet"} link={"/dashboard"} faIcon={faUser} />
      <BlockMenu name={"Slevy"} link={"/discounts"} faIcon={faTags} />
      <BlockMenu name={"Kontaktujte nás"} link={"/contact"} icon={letter} />
    </div>
  );
}

export default SecondMenu;
