import React, { Component } from "react";
import BusSchemaGrid from "./BusSchemaGrid";

export class SelectSeat extends Component {
  constructor() {
    super();
    this.state = {
      seatsReserved: null,
    };
  }

  onClickData(seat) {
    console.log("On click data " + seat);
    let reservedSeats = this.state.seatsReserved ?? this.props.defaultSeats;
    if (this.props.blocked == null || this.props.blocked.indexOf(seat) === -1) {
      if (reservedSeats.filter((info) => info.seat === seat).length === 1) {
        reservedSeats = reservedSeats.filter((info) => info.seat !== seat);
      } else {
        reservedSeats = reservedSeats.concat({ seat: seat, tariff: null });
      }

      reservedSeats.sort((a, b) => {
        return a.seat - b.seat;
      });

      this.setState({
        seatsReserved: reservedSeats,
      });
    }
    this.props.onChange({
      target: {
        value: reservedSeats,
      },
    });
  }

  render() {
    return (
      <div className="mista">
        <h2>Vyberte volné místo</h2>
        <BusSchemaGrid
          available={this.state.seatAvailable}
          reserved={(this.state.seatsReserved != null
            ? this.state.seatsReserved
            : this.props.defaultSeats
          ).map((info) => info.seat)}
          schema={this.props.schema}
          blocked={this.props.blocked}
          onClickData={this.onClickData.bind(this)}
        />
      </div>
    );
  }
}

export default SelectSeat;
