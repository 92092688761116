import React from "react";
import Autosuggest from "react-autosuggest";
import { debounce } from "throttle-debounce";
import { TextField } from "@material-ui/core";
import Cookies from "js-cookie";

class AutoComplete extends React.Component {
  constructor() {
    super();
  }

  state = {
    value: "",
    allSuggestions: [],
    filteredSuggestions: [],
  };

  componentWillMount() {
    this.onSuggestionsFetchRequested = debounce(
      500,
      this.onSuggestionsFetchRequested
    );
  }

  renderSuggestion = (suggestion) => {
    return (
      <div className="result">
        {/*<div>{suggestion.id}</div>*/}
        <div className="shortCode">{suggestion}</div>
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
    event.target = { value: newValue };
    this.props.onChange(event);
  };

  getFilteredSuggestions = (suggestions) => {
    let filteredSuggestions = [];

    suggestions.forEach((stop) => {
      let stopNormalized = (
        " " +
        stop
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/,/g, " ")
      ).toLowerCase();
      let valueNormalized =
        " " +
        this.state.value
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      if (stopNormalized.includes(valueNormalized)) {
        filteredSuggestions.push(stop);
      }
    });

    return filteredSuggestions;
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.trim().length === 0) {
      const recentsJson = Cookies.get("autocomplete-recents-" + this.props.id);
      if (recentsJson) {
        this.setState({
          filteredSuggestions: JSON.parse(recentsJson),
        });
      }
    } else {
      if (this.state.allSuggestions.length == 0) {
        fetch("https://backoffice.autobusyvkj.cz/api/webapp/stops")
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const results = data.map((r) => r.stop_name);
            this.setState({
              allSuggestions: results,
              filteredSuggestions: this.getFilteredSuggestions(results),
            });
          })
          .catch((error) => console.error(error));
      } else {
        this.setState({
          filteredSuggestions: this.getFilteredSuggestions(
            this.state.allSuggestions
          ),
        });
      }
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  renderInputComponent = (inputProps) => {
    return (
      <TextField
        className={inputProps.className}
        onChange={inputProps.onChange}
        id={inputProps.id}
        required
        label={inputProps.label}
        inputProps={inputProps}
      />
    );
  };

  shouldRenderSuggestions(value, reason) {
    return true;
  }

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    const recentsJson = Cookies.get("autocomplete-recents-" + this.props.id);
    let recents = [];
    if (recentsJson) {
      recents = JSON.parse(recentsJson);
    }
    recents = recents.filter(function (value, index, arr) {
      return value !== suggestion;
    });
    recents.unshift(suggestion);
    Cookies.set(
      "autocomplete-recents-" + this.props.id,
      JSON.stringify(recents.slice(0, 5)),
      { expires: 60 * 365 }
    );
  }

  render() {
    this.state.value = this.props.value;
    const inputProps = {
      ...this.props,
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={this.state.filteredSuggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={(suggestion) => suggestion}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        onSuggestionSelected={this.onSuggestionSelected.bind(this)}
      />
    );
  }
}

export default AutoComplete;
