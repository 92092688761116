import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Company from "./components/pages/Company";
import TimeTables from "./components/pages/TimeTables";
import Tours from "./components/pages/Tours";
import Contact from "./components/pages/Contact";
import Ads from "./components/pages/Ads";
import References from "./components/pages/References";
import History from "./components/pages/History";
import Career from "./components/pages/Career";
import Calculation from "./components/pages/Calculation";
import RoutePlan from "./components/pages/RoutePlan";
import Permissions from "./components/pages/Permissions";
import Taxes from "./components/pages/Taxes";
import Comunication from "./components/pages/Comunication";
import Reservation from "./pages/Reservation";
import Login from "./components/Login";
import Register from "./components/Register";
import Dashboard from "./components/Dashboard";
import Summary from "./pages/Reservation/Summary";
import { Failed } from "./components/Failed";
import Refund from "./components/pages/Refund";
import ResetSend from "./components/ResetSend";
import ResetPassword from "./components/ResetPassword";
import ProfileEdit from "./components/ProfileEdit";
import Tickets from "./components/Tickets";
import Discounts from "./components/Discounts";
import Homepage from "./pages/Homepage";
import Success from "./pages/Success";

import "./index.css";
import "./assets/styles/style.css";

const routing = (
  <Router>
    <div>
      <Route exact path="/" component={Homepage} />
      <Route path="/company" component={Company} />
      <Route path="/time-tables" component={TimeTables} />
      <Route path="/tours" component={Tours} />
      <Route path="/ads" component={Ads} />
      <Route path="/contact" component={Contact} />
      <Route path="/references" component={References} />
      <Route path="/history" component={History} />
      <Route path="/career" component={Career} />
      <Route path="/calculation" component={Calculation} />
      <Route path="/route" component={RoutePlan} />
      <Route path="/permissions" component={Permissions} />
      <Route path="/taxes" component={Taxes} />
      <Route path="/comunication" component={Comunication} />
      <Route path="/reservation" component={Reservation} />
      <Route path="/login" component={Login} />
      <Route path="/reset-send" component={ResetSend} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/tickets" component={Tickets} />
      <Route exact path="/discounts" component={Discounts} />
      <Route path="/summary" component={Summary} />
      <Route path="/finished" component={Success} />
      <Route path="/failed" component={Failed} />
      <Route path="/refund" component={Refund} />
      <Route path="/profile/edit" component={ProfileEdit} />
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
