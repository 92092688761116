import React from "react";
import Menu from "../Menu";
import Button from "../Button";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import busImg from "../../assets/images/busImg.png";
import { Helmet } from "react-helmet";
import MenuHam from "../MenuHam/MenuHam";

function Ads() {
  return (
    <div>
      <Helmet>
        <title>Reklama</title>
      </Helmet>
      <MenuHam/>
      <Menu />

      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <div className="leftSide">
            <h1>Reklama</h1>
            <p>
              Proč zvolit reklamu u nás? Naše autobusy denně přepraví stovky
              cestujících a pohybují se na místech s vysokou koncentrací lidí.
              Nenabízíme reklamu pouze při volebních kampaních a velkým
              společnostem, avšak i malým lokálním řemeslníkům, obchodníkům a
              drobným výrobcům. Na výběr nemáte jen z regionálních a dálkových
              linek, ale i ze zájezdových vozů flotily. Nabízíme širokou paletu
              možností jak prezentovat Vaši firmu.
              <ul>
                <li>Spoty na obrazovkách uvnitř autobusu</li>
                <li>Letáky rozdávané při vstupu do autobusu</li>
                <li>Výlep letáků uvnitř autobusu</li>
                <li>Visačky na madla sedadel</li>
                <li>Částečný polep, nebo celoplošný polep vozu</li>
              </ul>
            </p>
            <img className="busImg" src={busImg} alt="Auotbus" />
          </div>
          <div className="rightSide">
            <Button
              name="Spoty na obrazovkách uvnitř autobusu"
              link="/calculation"
            />
            <Button
              name="Letáky rozdávané při vstupu do autobusu"
              link="/route"
            />
            <Button name="Výlep letáků uvnitř autobusu" link="/permissions" />
            <Button name="Visačky na madla sedadla" link="/taxes" />
            <Button
              name="Částečný polep, nebo celoplošný polep vozu"
              link="/comunication"
            />
          </div>
        </div>
      </div>

      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default Ads;
