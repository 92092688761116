import React, { useState, useRef } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";

import MenuHam from "../MenuHam/MenuHam";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  success: {
    color: "#44FF44",
    fontWeight: "bold",
  },
  error: {
    color: "#FF2222",
    fontWeight: "bold",
  },
});

function SignIn(props) {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  const { classes } = props;

  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div>
      <Helmet>
        <title>VKJ Autobusy</title>
      </Helmet>
      <MenuHam />
      <Menu />
      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <main className={classes.main}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Obnovení hesla
              </Typography>
              <form
                className={classes.form}
                onSubmit={(e) => e.preventDefault() && false}
              >
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">E-mail</InputLabel>
                  <Input
                    id="email"
                    name="email"
                    required={true}
                    autoComplete="off"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <div className={classes.success}>{successMessage}</div>
                <div className={classes.error}>{errorMessage}</div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={resetSend}
                  className={classes.submit}
                >
                  Odeslat
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/login"
                  className={classes.submit}
                >
                  Zpět na přihlášení
                </Button>
              </form>
            </Paper>
          </main>
        </div>
      </div>
      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );

  async function resetSend() {
    try {
      const response = await fetch(
        "https://dev2.autobusyvkj.cz/api/send-reset-link",
        {
          credentials: "include",
          mode: "cors", // no-cors, *cors, same-origin
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
            //"Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      let data = await response.json();
      console.log(data);
      if (data.error) {
        setSuccessMessage("");
        setErrorMessage(data.error);
      } else {
        setErrorMessage("");
        setSuccessMessage(data.status);
      }
      //props.history.replace("/login");
    } catch (error) {
      setErrorMessage("Chyba! Zkontrolujte zadané údaje.");
    }
  }
}

export default withRouter(withStyles(styles)(SignIn));
