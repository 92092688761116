import React from 'react';
import '../assets/styles/style.css';


const ContactBlock = (props) => {
    return (
        <div className="blockContactItem">
            <div>
                <h2>{props.name}</h2>
                <span>{props.number}</span>
                <span>{props.time}</span>
            </div>
        </div>
    );
};

export default ContactBlock;
