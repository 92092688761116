import React, { useState } from "react";
import {
  Typography,
  Paper,
  Avatar,
  Button,
  FormControl,
  Input,
  InputLabel,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import withStyles from "@material-ui/core/styles/withStyles";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import MenuHam from "../MenuHam/MenuHam";
import Cookies from "js-cookie";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;


const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  success: {
    marginTop: 5,
  },
  error: {
    marginTop: 5,
    color: "#ff0000",
  },
});

function Register(props) {
  const [userJson, setUserJson] = useState(Cookies.get("user"));
  let user = userJson !== "" ? JSON.parse(userJson) : null;

  const { classes } = props;

  const [name, setName] = useState(user ? user.name : null);
  const [email, setEmail] = useState(user ? user.email : null);
  const [phone, setPhone] = useState(user ? user.phone : null);

  const [current_password, setCurrentPassword] = useState("");

  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (!userJson && Capacitor.getPlatform() === "ios") {
    Storage.get({ key: "user" }).then((data) => {
      console.log("From storage : " + JSON.stringify(data.value));
      if (data.value) {
        setUserJson(data.value);
      } else {
        props.history.replace("/login");
      }
    });

    return (
      <div>
        <Helmet>
          <title>VKJ Autobusy</title>
        </Helmet>
        <MenuHam />
        <Menu />
        <div className="bodyContHP"></div>
      </div>
    );
  } else {
    return (
      <div>
        <Helmet>
          <title>VKJ Autobusy</title>
        </Helmet>
        <MenuHam />
        <Menu />
        <div className="bodyContHP">
          <div className="bodyInnerHP">
            <main className={classes.main}>
              <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Upravit údaje
                </Typography>
                <form
                  className={classes.form}
                  onSubmit={(e) => e.preventDefault() && false}
                >
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="name">Jméno a příjmení</InputLabel>
                    <Input
                      id="name"
                      name="name"
                      autoComplete="off"
                      autoFocus
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">E-mail</InputLabel>
                    <Input
                      id="email"
                      name="email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="phone">Telefonní číslo</InputLabel>
                    <Input
                      id="phone"
                      name="phone"
                      autoComplete="off"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={save}
                    className={classes.submit}
                  >
                    Uložit
                  </Button>
                </form>
                <br />
                <br />

                <div className={classes.success}>{successMessage}</div>
                <div className={classes.error}>{errorMessage}</div>

                <br />
                <br />
                <form
                  className={classes.form}
                  onSubmit={(e) => e.preventDefault() && false}
                >
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Aktuální heslo</InputLabel>
                    <Input
                      name="current_password"
                      type="password"
                      id="current_password"
                      autoComplete="off"
                      value={current_password}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Nové heslo</InputLabel>
                    <Input
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password_confirmation">
                      Nové heslo znovu
                    </InputLabel>
                    <Input
                      name="password_confirmation"
                      type="password"
                      id="password_confirmation"
                      autoComplete="off"
                      value={password_confirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={changePassword}
                    className={classes.submit}
                  >
                    Změnit heslo
                  </Button>
                </form>
              </Paper>
            </main>
          </div>
        </div>
        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  }

  async function save() {
    try {
      const response = await fetch(
        "https://dev2.autobusyvkj.cz/api/profile/edit",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            name,
            email,
            phone,
          }), // body data type must match "Content-Type" header
        }
      );
      //return response.json(); // parses JSON response into native JavaScript objects

      let data = await response.json();
      if (data.id != user.id) {
        let error = "";
        if (data.name) {
          error += data.name + "\n";
        }

        if (data.email) {
          error += data.email + "\n";
        }

        if (data.phone) {
          error += data.phone + "\n";
        }
        setSuccessMessage("");
        setErrorMessage(error);
      } else {
        setErrorMessage("");
        setSuccessMessage("Změny uloženy");
        Cookies.set("user", JSON.stringify(user), { expires: 60 * 365 });
      }
    } catch (error) {
      setErrorMessage(error);
    }
  }

  async function changePassword() {
    try {
      const response = await fetch(
        "https://dev2.autobusyvkj.cz/api/profile/change-password",
        {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            current_password,
            password,
            password_confirmation,
          }), // body data type must match "Content-Type" header
        }
      );
      //return response.json(); // parses JSON response into native JavaScript objects

      let data = await response.json();
      if (data.id != user.id) {
        setSuccessMessage("");
        setErrorMessage(data.password);
      } else {
        setErrorMessage("");
        setSuccessMessage("Změny uloženy");
        Cookies.set("user", JSON.stringify(user), { expires: 60 * 365 });
      }
    } catch (error) {
      setErrorMessage(error);
    }
  }
}

export default withRouter(withStyles(styles)(Register));
