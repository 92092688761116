import React from "react";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import Button from "../Button";
import podpis from "../../assets/images/podpis.png";
import { Helmet } from "react-helmet";
import MenuHam from "../MenuHam/MenuHam";

function Company() {
  return (
    <div>
      <Helmet>
        <title>Kariéra</title>
      </Helmet>
      <MenuHam/>
      <Menu />

      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <div className="leftSide">
            <h1>Kariéra</h1>
            <p>
              Staňte se součástí našeho kolektivu a pomozte nám rozšiřovat a
              zkvalitňovat služby vedoucí k potěše našich zákazníků. Nabízíme
              Vám zázemí jedné z prvních autobusových firem novodobé historie
              České republiky. U nás se můžete spolehnout na pravidelnou mzdu za
              provedenou práci. Dále nabízíme možnost turnusových pracovních
              režimů s bezplatným ubytováním na naší ubytovně, nebo za
              zvýhodněnou cenu ve služebních bytech.{" "}
            </p>

            <p>Volné pracovní pozice:</p>
            <p>
              <ul>
                <li>Řidič autobusu v mezinárodní linkové dopravě – Klatovy</li>
                <li>Řidič autobusu v dálkové linkové dopravě – Sušice-Praha</li>
                <li>
                  Řidič autobusu v dálkové linkové dopravě – Klatovy-Plzeň-Praha
                </li>
                <li>
                  Řidič autobusu v meziměstské linkové dopravě – Klatovy-Plzeň
                </li>
                <li>
                  Řidič autobusu v meziměstské linkové dopravě – Klatovy-Nýrsko
                </li>
                <li>
                  Řidič autobusu vnitrostátní a mezinárodní zájezdové dopravy –
                  Klatovy, Praha, Sušice
                </li>
                <li>Automechanik – Klatovy</li>
                <li>Autoelektrikář - Klatovy</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default Company;
