import React from 'react';

const Indicators = (props) => {
    return (
        <div className="contIndicators">
            <div className="colorAndText"><div className="squareAvailable"></div> volné</div>
            <div className="colorAndText"><div className="squareBlocked"></div> obsazené</div>
            <div className="colorAndText"><div className="squareSelected"></div> moje</div>
        </div>
    );
};

export default Indicators;
