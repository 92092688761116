import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import "../assets/styles/style.css";

const BlockMenu = (props) => {
  return (
    <div className="blockMenuItem">
      <Link to={props.link}>
        {props.faIcon ? (
          <FontAwesomeIcon className="iconBlock" icon={props.faIcon} />
        ) : (
          <img className="iconBlock" src={props.icon} alt="Icon" />
        )}

        <br />
        {props.name}
      </Link>
    </div>
  );
};

export default BlockMenu;
