import React from "react";
import Menu from "../components/Menu";
import Banner from "../components/Banner";
import SecondMenu from "../components/SecondMenu";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Capacitor } from "@capacitor/core";
import AppUrlListener from "../components/DeepLinking/AppUrlListener";
import MenuHam from "../components/MenuHam/MenuHam";

function Homepage() {
  return (
    <div>
      <AppUrlListener></AppUrlListener>
      <Helmet>
        <title>VKJ Autobusy</title>
      </Helmet>
      <MenuHam />
      <Menu />
      <div className="bodyContHP">
        <div className="bodyInnerHP">
          <Banner />
          <SecondMenu />
        </div>
      </div>
      {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
    </div>
  );
}

export default Homepage;
