import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './MenuMob.styled';
import {Link} from "react-router-dom";
import Logo from "../Logo";

const MenuMob = ({ open, ...props }) => {

    const isHidden = open ? true : false;
    const tabIndex = isHidden ? 0 : -1;

    return (
        <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
            <li className="logoMenuMobile"><Link to="/"><Logo/></Link></li>
            <li><Link to="/company">Společnost</Link></li>
            <li><Link to="/time-tables">Jízdní řády</Link></li>
            <li><Link to="/tours">Zájezdová doprava</Link></li>
            <li><Link to="/contact">Kontakt</Link></li>
            <li><Link to="/ads">Reklama</Link></li>
            <li><Link to="/dashboard">Můj účet</Link></li>
        </StyledMenu>
    )
}

MenuMob.propTypes = {
    open: bool.isRequired,
}

export default MenuMob;
