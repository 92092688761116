import { MenuItem, Select } from "@material-ui/core";
import React from "react";

class SeatDiscountSelect extends React.Component {
  onDiscountSelected = (seat) => (e) => {
    let seatInfo = this.props.seats.filter((info) => info.seat === seat)[0];
    seatInfo.tariff = this.props.discounts.filter(
      (d) => d.id === e.target.value
    )[0];
    this.props.onDiscountSelected(seatInfo);
  };

  render() {
    return (
      <div className="right">
        <h4>Zvolená sedadla: ({this.props.seats.length})</h4>
        <ul className="container">
          {this.props.seats.map((seatInfo) => (
            <li key={seatInfo.seat}>
              <span className="seat">{seatInfo.seat}</span>
              <Select
                className="tariffSelect"
                onChange={this.onDiscountSelected(seatInfo.seat).bind(this)}
                defaultValue={seatInfo.tariff?.id ?? 1}
              >
                {this.props.discounts.map((discount, i) => (
                  <MenuItem key={i} value={discount.id} className="tariffItem">
                    {discount.name}
                  </MenuItem>
                ))}
              </Select>
              <span className="price">
                {Math.round(
                  (seatInfo.tariff?.rate ?? 1) * this.props.basePrice
                )}{" "}
                Kč
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default SeatDiscountSelect;
