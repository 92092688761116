import React from "react";
import "../assets/styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <div className="footerCont">
      <div className="footer">
        <p>
          <a target="_blank" href="/data/web/SPP_VKJ.pdf">
            Obchodní podmínky
          </a>
        </p>
        <p>
          <a target="_blank" href="/data/web/GDPR.pdf">
            Zásady ochrany osobních údajů
          </a></p>
        <p>Autobusy VKJ s. r. o., Molákova 581/28, Praha, 18600 | IČO: 27244181</p>
        <p>info@autobusyvkj.cz</p>
        <p>
          <FontAwesomeIcon icon={faPhoneAlt} />
          376&nbsp;311&nbsp;022
        </p>
      </div>
    </div>
  );
}

export default Footer;
