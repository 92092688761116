import React from 'react';
import logo from '../assets/images/LogoVKJ.png';

function Logo() {

    const logoHP = {
        width: "100%"
    };

    return (
        <img style={logoHP} src={logo} alt="Logo" />
    );
}

export default Logo;
