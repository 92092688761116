import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { LinearProgress } from "@material-ui/core";
import { Capacitor } from "@capacitor/core";

import ConnnectionsSearch from "./Reservation/ConnnectionsSearch";
import ConnectionsResult from "./Reservation/ConnectionsResult";
import PersonalInfoForm from "./Reservation/PersonalInfoForm";
import Summary from "./Reservation/Summary";
import SeatSelect from "./Reservation/SeatSelect";
import Footer from "../components/Footer";
import MenuHam from "../components/MenuHam/MenuHam";
import Menu from "../components/Menu";

export class Reservation extends Component {
  today = new Date();

  state = {
    step: 1,
    fromPlace: "",
    toPlace: "",
    departure: this.today.toISOString().split("T")[0],
    departureTime:
      (this.today.getHours() < 10 ? "0" : "") +
      this.today.getHours() +
      ":" +
      (this.today.getMinutes() < 10 ? "0" : "") +
      this.today.getMinutes(),
    email: "",
    name: "",
    phoneNumber: "",
    seatsWithTariffs: [],
    basePrice: "",
    selectedConnection: null,
    isGDPRApproved: false,
    isTermsApproved: false,
  };

  // Krok vpřed
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Krok zpět
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Krok zpět
  addStep = (x) => {
    const { step } = this.state;
    this.setState({
      step: step + x,
    });
  };

  // Změna hodnoty inputu
  handleChange = (input) => (e) => {
    if (input === "isGDPRApproved" || input === "isTermsApproved") {
      console.log("handle change " + input + " " + e.target.checked);
      this.setState({ [input]: e.target.checked });
    } else {
      this.setState({ [input]: e.target.value });
    }
  };

  selectConnection = (connectionDetails) => {
    this.setState({ selectedConnection: connectionDetails });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Rezervace jízdenek</title>
        </Helmet>
        <MenuHam />
        <Menu />
        <div className="bodyContHP">
          <div className="bodyInnerHP">
            {this.renderStep()}
            <h3 className="progressTitle">Krok {this.state.step} z 6</h3>
            <LinearProgress
              className="progressBar"
              variant="determinate"
              value={this.state.step * 15}
            />
          </div>
        </div>
        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  }

  renderStep() {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <ConnnectionsSearch
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={this.state}
          />
        );
      case 2:
        return (
          <ConnectionsResult
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            selectConnection={this.selectConnection}
            values={this.state}
          />
        );
      case 3:
        return (
          <SeatSelect
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={this.state}
          />
        );
      case 4:
        return (
          <PersonalInfoForm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={this.state}
          />
        );
      case 5:
        return (
          <Summary
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            addStep={this.addStep}
            handleChange={this.handleChange}
            values={this.state}
          />
        );
      default:
        return (
          <ConnnectionsSearch
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={this.state}
          />
        );
    }
  }
}

export default Reservation;
