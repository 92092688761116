import React from 'react';
import '../assets/styles/style.css';
import banner from "../assets/images/banner.jpg";

function Banner() {
    return (
        <img className="banner" src={banner} alt="Banner" />
    );
}

export default Banner;
