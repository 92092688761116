import React from 'react';
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import {Helmet} from "react-helmet";

function History() {
    return (
        <div>
            <Helmet>
                <title>Historie a současnost</title>
            </Helmet>
            <Menu />
            <div className="bodyContHP">
                <div className="bodyInnerHP">
                    <h1>Historie a současnost</h1>

                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default History;
