import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { StyledBackButton } from "./BackButton.styled";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

function BackButton(props) {
  if (window.location.pathname === "/") {
    return <div></div>;
  } else {
    return (
      <div>
        <StyledBackButton onClick={() => props.history.goBack()}>
          <span>
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </StyledBackButton>
      </div>
    );
  }
}

export default withRouter(BackButton);
