import React from "react";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import { Helmet } from "react-helmet";
import { LinearProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import StornoForm from "../StornoForm";
import MenuHam from "../MenuHam/MenuHam";

const Refund = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  return <StornoForm token={token} />;
};

export default Refund;
