import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
const { App: CapApp } = Plugins;

const AppUrlListener = () => {
  let history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data) => {
      const slug = data.url.split("autobusyvkj.cz").pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
