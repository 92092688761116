import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "../Menu";
import Footer from "../Footer";
import { Capacitor } from "@capacitor/core";
import Cookies from "js-cookie";
import MenuHam from "../MenuHam/MenuHam";
import { Plugins } from "@capacitor/core";

const { Storage } = Plugins;

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    paddingTop: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px ${
      theme.spacing.unit * 2
    }px`,
  },
  ticketList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  ticketItem: {
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    backgroundColor: "#EEEEEE",
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  error: {
    marginTop: 5,
    color: "#ff0000",
  },
});

function Dashboard(props) {
  const [open, setOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const node = useRef();
  const menuId = "main-menu";
  const { classes } = props;

  const [userJson, setUserJson] = useState(Cookies.get("user"));

  if (!userJson && Capacitor.getPlatform() === "ios") {
    Storage.get({ key: "user" }).then((data) => {
      console.log("From storage : " + JSON.stringify(data.value));
      if (data.value) {
        setUserJson(data.value);
      } else {
        props.history.replace("/login");
      }
    });
  }

  useEffect(() => {
    if (!userJson && Capacitor.getPlatform() !== "ios") {
      return;
    }

    fetch("https://dev2.autobusyvkj.cz/api/tickets", {
      credentials: "include",
      mode: "cors", // no-cors, *cors, same-origin
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        setTickets(data);
      })
      .catch((error) => console.log("Tickets loading error " + error));
  }, []);

  if (!userJson && Capacitor.getPlatform() !== "ios") {
    props.history.replace("/login");
    return null;
  }
  try {
    const user = JSON.parse(userJson);

    return (
      <div>
        <Helmet>
          <title>VKJ Autobusy</title>
        </Helmet>
        <MenuHam />
        <Menu />
        <div className="bodyContHP">
          <div className="bodyInnerHP">
            <main className={classes.main}>
              {tickets && (
                <Paper className={classes.paper}>
                  <h1>Jízdenky</h1>
                  <div className={classes.error}>{errorMessage}</div>
                  <ul className={classes.ticketList}>
                    {tickets.map((ticket) => (
                      <li className={classes.ticketItem} key={ticket.code}>
                        Datum: {ticket.ticket_date}
                        <br /> Z: {ticket.stop_from_name}
                        <br /> Do: {ticket.stop_to_name}
                        <br /> Kód: {ticket.code}
                        <br /> Cena: {ticket.final_price} ,- Kč
                      </li>
                    ))}
                  </ul>
                </Paper>
              )}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </main>
          </div>
        </div>
        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  } catch (error) {
    console.log(error);

    return (
      <div>
        <Helmet>
          <title>VKJ Autobusy</title>
        </Helmet>
        <MenuHam />
        <Menu />
        <div className="bodyContHP"></div>
        {Capacitor.getPlatform() === "web" ? <Footer /> : <div></div>}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Dashboard));
