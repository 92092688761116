import React, { Component } from "react";
import {  TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import AutoComplete from "../../components/AutoComplete";

class ConnnectionsSearch extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  switchStops() {
    let newTo = this.props.values.fromPlace;
    let newFrom = this.props.values.toPlace;
    this.props.handleChange("fromPlace")({ target: { value: newFrom } });
    this.props.handleChange("toPlace")({ target: { value: newTo } });
  }

  render() {
    const { values, handleChange } = this.props;

    let disabled = true;

    let fromLength = values.fromPlace.length;
    let toLength = values.toPlace.length;
    let dptLength = values.departure.length;
    let dptTimeLength = values.departureTime.length;

    const isDisabled = (disabled) => {
      if (
        fromLength == 0 ||
        toLength == 0 ||
        dptLength == 0 ||
        dptTimeLength == 0
      ) {
        disabled = true;
        return disabled;
      } else {
        disabled = false;
        return disabled;
      }
    };

    if (window.location.href == "https://www.autobusyvkj.cz/reservation") {
      window.location.href = "https://amsbus.cz/vkj/koupitjizdenku/";
    }

    return (
      <div>
        <div className="firstRow">
          <h1 className="titleReservation">Hledat spoj</h1>
        </div>
        <div className="formCont">
          <form id="formFirstStep" onSubmit={this.handleSubmit}>
            <AutoComplete
              className="textFieldWithSwitch"
              onChange={handleChange("fromPlace")}
              value={values.fromPlace}
              id="from-place-autocomplete"
              label="Odkud"
            />
            <AutoComplete
              onChange={handleChange("toPlace")}
              className="textFieldWithSwitch"
              value={values.toPlace}
              id="to-place-autocomplete"
              label="Kam"
            />

            <Button id="switchButton" onClick={this.switchStops.bind(this)}>
              <FontAwesomeIcon icon={faArrowUp} />
              <FontAwesomeIcon icon={faArrowDown} />
            </Button>
            <br />

            <TextField
              onChange={handleChange("departure")}
              id="date"
              label="Odjezd"
              type="date"
              className="textFieldHalf textFieldMargin"
              defaultValue={values.departure}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              onChange={handleChange("departureTime")}
              id="time"
              label=" "
              type="time"
              defaultValue={values.departureTime}
              className="textFieldHalf"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <div className="thirdRow">
              <Button
                disabled={isDisabled(disabled)}
                type="submit"
                color="primary"
                variant="contained"
                onClick={this.continue}
              >
                Pokračovat
              </Button>
            </div>
          </form>
        </div>        
      </div>
    );
  }
}
export default ConnnectionsSearch;
