import React from 'react';
import { Link } from 'react-router-dom'
import '../assets/styles/style.css';


const Button = (props) => {
    return (
            <Link className="myButton" to={props.link}>
                {props.name}
            </Link>
    );
};

export default Button;
