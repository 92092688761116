import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet/es/Helmet";
import {
  FormControl,
  Input,
  InputLabel,
  LinearProgress,
  Menu,
  TextField,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { Capacitor } from "@capacitor/core";
import MenuHam from "../../components/MenuHam/MenuHam";
import Footer from "../../components/Footer";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;


export class PersonalInfoForm extends Component {
  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  state = {
    isLoginShowing: false,
    email: "",
    password: "",
    errorMessage: "",
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  isDisabled = () => {
    const regexpEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexpPhone = /^((\+[0-9]{12})|([0-9]{9}))$/;

    return (
      this.props.values.isGDPRApproved === false ||
      this.props.values.name.length === 0 ||
      !regexpEmail.test(this.props.values.email) ||
      !regexpPhone.test(this.props.values.phoneNumber)
    );
  };

  login = async function () {
    try {
      const response = await fetch("https://dev2.autobusyvkj.cz/api/login", {
        credentials: "include",
        mode: "cors", // no-cors, *cors, same-origin
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
          "Content-Type": "application/json",
          //"Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({
          email: this.state.email,
          password: this.state.password,
        }),
      });
      let user = (await response.json()).user;

      Cookies.set("user", JSON.stringify(user), { expires: 60 * 365 });
      await Storage.set({
        key: "user",
        value: JSON.stringify(user),
      });

      this.setState({
        isLoginShowing: false,
      });
    } catch (error) {
      this.setState({
        errorMessage: JSON.stringify(error),
      });
    }
  };

  render() {
    const { values, handleChange, nextStep } = this.props;

    const userJson = Cookies.get("user");

    if (!userJson && Capacitor.getPlatform() === "ios") {
      Storage.get({ key: "user" }).then((data) => {
        console.log("From storage : " + JSON.stringify(data.value));
        if (data.value) {
            user = JSON.parse(data.value);
            if (!values.email) {
              values.email = user.email;
            }
            if (!values.name) {
              values.name = user.name;
            }
            if (!values.phoneNumber) {
              values.phoneNumber = user.phone;
            }
            if (values.email && values.name && values.phoneNumber) {
              nextStep();
            }
        }
      });
    }

    let user = false;
    if (userJson) {
      user = JSON.parse(userJson);
      if (!values.email) {
        values.email = user.email;
      }
      if (!values.name) {
        values.name = user.name;
      }
      if (!values.phoneNumber) {
        values.phoneNumber = user.phone;
      }
      if (values.email && values.name && values.phoneNumber) {
        values.loggedIn = true;
        nextStep();
      }
    }

    return (
      <div>
        <div className="firstRow">
          <h1 className="titleReservation">Osobní údaje</h1>
        </div>

        <div className="secondRow">
          {this.state.isLoginShowing ? (
            <form onSubmit={(e) => e.preventDefault() && false}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="off"
                  autoFocus
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Heslo</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="off"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </FormControl>

              <div>{this.state.errorMessage}</div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.login.bind(this)}
              >
                Přihlásit
              </Button>
              <Button
                className="btnRegister"
                onClick={() => this.setState({ isLoginShowing: false })}
              >
                Zrušit přihlášení
              </Button>
            </form>
          ) : (
            <form id="loginForm">
              <TextField
                type="email"
                defaultValue={values.email}
                onChange={handleChange("email")}
                className="loginInput"
                id="standard-basic"
                label="E-mail"
              />
              <TextField
                type="text"
                defaultValue={values.phoneNumber}
                onChange={handleChange("phoneNumber")}
                className="loginInput"
                id="standard-basic"
                label="Telefonní číslo"
              />
              <TextField
                defaultValue={values.name}
                onChange={handleChange("name")}
                className="loginInput"
                id="standard-basic"
                label="Jméno a příjmení"
                type="text"
              />

              <Button
                className="btnRegister"
                onClick={() => this.setState({ isLoginShowing: true })}
              >
                Již máte účet ?
              </Button>

              <p>
                <input
                  name="isGDPRApproved"
                  type="checkbox"
                  defaultChecked={values.isGDPRApproved}
                  onChange={handleChange("isGDPRApproved")}
                />
                Tímto potvrzuji, že jsem si přečetl/a&nbsp;
                <a href="/data/web/GDPR.pdf" target="_blank">
                  zásady ochrany osobních údajů.
                </a>
              </p>
            </form>
          )}
        </div>

        <div className="thirdRow">
          <Button color="secondary" variant="contained" onClick={this.back}>
            Zpět
          </Button>

          <Button
            disabled={this.isDisabled()}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Pokračovat
          </Button>
        </div>
      </div>
    );
  }
}

export default PersonalInfoForm;
