import React from 'react';
import { Link } from 'react-router-dom'
import Logo from "./Logo";
import '../assets/styles/style.css';


function Menu() {


    return (
        <nav id="menu" className="topnav">
            <ul>
                <li className="logoMenu"><Link to="/"><Logo/></Link></li>
                <li><Link to="/company">Společnost</Link></li>
                <li><Link to="/time-tables">Jízdní řády</Link></li>
                <li><Link to="/tours">Zájezdová doprava</Link></li>
                <li><Link to="/contact">Kontakt</Link></li>
                <li><Link to="/ads">Reklama</Link></li>
                 <li><Link to="/dashboard">Můj účet</Link></li> 
            </ul>
        </nav>
    );
}

export default Menu;
