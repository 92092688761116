import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import {
  faInfoCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class ConnectionsResult extends Component {
  state = {
    loading: true,
    connections: null,
    selectedConnection: -1,
    noConnections: true,
  };

  async componentDidMount() {
    const url =
      "https://backoffice.autobusyvkj.cz/api/webapp/find-connections?fsdgfdrg";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "departure=" +
        this.props.values.departure +
        "&departureTime=" +
        this.props.values.departureTime +
        "&fromPlace=" +
        this.props.values.fromPlace +
        "&toPlace=" +
        this.props.values.toPlace,
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        this.setState({ connections: myJson, loading: false });
      })
      .catch((error) => console.error(error));
  }

  continue = (e) => {
    e.preventDefault();
    this.props.selectConnection(
      this.state.connections[this.state.selectedConnection]
    );
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  selectConnection = (i) => {
    if (this.isConnectionReservable(this.state.connections[i])) {
      this.setState({ selectedConnection: i });
    }
  };

  isConnectionReservable = (connection) => {
    var currentTime = new Date();
    var timePlus30 = new Date(currentTime.getTime() + 30 * 60 * 1000);

    var connectionTime = new Date();
    var dateString = this.props.values.departure;
    connectionTime.setFullYear(
      dateString.substr(0, 4),
      parseInt(dateString.substr(5, 2)) - 1,
      dateString.substr(8, 2)
    );
    connectionTime.setHours(connection.departure_time.substr(0, 2));
    connectionTime.setMinutes(connection.departure_time.substr(2, 2));

    if (connectionTime > timePlus30) {
      return true;
    }
    return false;
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div className="firstRow">
            <h1 className="titleReservation">Načítám...</h1>
          </div>
        </div>
      );
    }

    if (!this.state.connections === null) {
      return (
        <div>
          <div className="firstRow">
            <h1 className="titleReservation">Žádné výsledky nenalezeny.</h1>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="firstRow">
          <h1 className="titleReservation">Výsledky hledání</h1>
        </div>
        <div className="resultCont">
          <div>
            <div>
              <div className="listHead">
                <span>Spoj / Linka:</span>
                <span>Ze stanice:</span>
                <span>Odjezd:</span>
                <span>Do stanice:</span>
                <span>Příjezd:</span>
              </div>

              <ul className="connList">
                {this.state.connections.length > 0 ? (
                  this.state.connections.map((connection, i) => (
                    <li
                      className={
                        this.state.selectedConnection == i ? "highlight" : ""
                      }
                      onClick={() => this.selectConnection(i)}
                      key={i}
                    >
                      <span>
                        {connection.connection_number} /{" "}
                        {connection.line_number}
                      </span>

                      <span>{connection.stop_from}</span>

                      <span>
                        {connection.departure_time.substr(0, 2)}:
                        {connection.departure_time.substr(2, 2)}
                        {this.isConnectionReservable(connection)
                          ? ""
                          : " - Již nelze rezervovat."}
                      </span>

                      <span>{connection.stop_to}</span>

                      <span>
                        {connection.stop_to_arrival_time
                          ? connection.stop_to_arrival_time.substr(0, 2)
                          : connection.stop_to_departure_time.substr(0, 2)}
                        :
                        {connection.stop_to_arrival_time
                          ? connection.stop_to_arrival_time.substr(2, 2)
                          : connection.stop_to_departure_time.substr(2, 2)}
                      </span>
                    </li>
                  ))
                ) : (
                  <p className="noConn">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    Žádná spojení nenalezena. Upravte prosím parametry hledání.
                  </p>
                )}
              </ul>

              <FontAwesomeIcon icon={faInfoCircle} />
              <p className="subTitleConnList">
                Zvolte spoj a klikněte na pokračovat (rezervovat místenky je
                možné nejpozději 30 minut před odjezdem)
              </p>
            </div>
          </div>
        </div>

        <div>
          <Button color="secondary" variant="contained" onClick={this.back}>
            Zpět
          </Button>

          <Button
            disabled={this.state.selectedConnection == -1}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Pokračovat
          </Button>
        </div>
      </div>
    );
  }
}

export default ConnectionsResult;
