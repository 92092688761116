import React from "react";

class BusSchemaGrid extends React.Component {
  render() {
    return (
      <div className="container">
        <table className="grid">
          <thead>
            <tr>
              <td colSpan="100">
                <div className="left">
                  {/* <h4>Volná sedadla: ({seatCount == 0 ? 'No seats available' : seatCount})</h4> */}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {this.props.schema.map((row, i) => (
              <tr key={i}>
                {row.seats.map((seat, j) => (
                  <td
                    key={i + "-" + j}
                    className={
                      seat == null
                        ? "empty"
                        : this.props.reserved.indexOf(seat) > -1
                        ? "reserved"
                        : this.props.blocked.indexOf(seat) > -1
                        ? "blocked"
                        : "available"
                    }
                    key={i + "-" + j}
                    onClick={(e) => this.onClickSeat(seat)}
                  >
                    {seat}{" "}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  onClickSeat(seat) {
    this.props.onClickData(seat);
  }
}

export default BusSchemaGrid;
